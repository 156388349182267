@import url(https://fonts.googleapis.com/css2?family=Almendra+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-toremp{
    font-family: 'Almendra SC', serif!important;
}

.dropdownCategorias{
    width:800px;
}

.cont_imagen_superfamilia_menu_categoria{
    max-width: 80px;
}


@media (max-width:768px){
    .dropdownCategorias{
        width:540px;
    }
}
@media (max-width:575px){
    .dropdownCategorias{
        width:400px;
    }
}
@media (max-width:400px){
    .dropdownCategorias{
        width:300px;
    }
    .cont_imagen_superfamilia_menu_categoria{
        max-width: 60px;
    }
}

.imagen_banner{
    top:-100px;
}

.imagen_superfamilia{
    max-width:120px;
}

.titulo_superfamilia{
    font-size: 34px!important;
}

.cont_general_titulo{
    top:-120px;
}
.contenedor_imagen_familia{
    height:250px;
}


@media (max-width:480px){
    .contenedor_imagen_familia{
        height:180px;
    }
   
    
}
@media (max-width:380px){
    .titulo_superfamilia{
        font-size: 24px!important;
    }
    .nombre_familia_img{
        font-size:14px!important;
    }
    .contenedor_imagen_familia{
        height:150px;
    }
}

@media (max-width:768px){
    .imagen_superfamilia{
        max-width:100px;
    }
    .cont_general_titulo{
        top:-110px;
    }
    .imagen_banner{
        top:0px;
    }
}
@media (max-width:576px){
    .imagen_superfamilia{
        max-width:80px;
    }
    .cont_general_titulo{
        top:-100px;
    }
    
}



iframe{
    height:300px!important;
    width:500px!important;
}

